import hillo from 'hillo'

export async function getOrderDetailLogById (id) {
  return (await hillo.post('Complex.php?op=getDishesOrdersFullLogOrderId', {
    orderId: id
  })).content
}

export function formatLogDish (dish) {
  if (dish.displayApply) {
    dish.modName = dish.displayApply.map(it => it.value).join(',')
  }
  return dish
}
